<template>
  <div id="home">
    <van-nav-bar
        title="注册"
        fixed
    />
    <van-image class="a-us" :src="banner"></van-image>
    <div class="login">
      <div class="r-s rs-login">
        <h3>登录/注册<span>LOGIN / REGISTRATION</span></h3>
        <div class="u-login u-reg-form">
          <div class="u-pre">中国（+86)</div>
          <input type="number" v-model="regform.account" placeholder="请输入您的手机号码">
          <input type="password" v-model="regform.password" placeholder="请输入登录密码">
          <input type="password" v-model="regform.confirm_pass" placeholder="请输重复登录密码">
          <div class="u-code">
            <input type="number" v-model="regform.code" placeholder="请输入短信验证码">
            <div class="u-c-djs" v-if="issend == false" @click.stop="getcode">获取验证码</div>
            <div class="u-c-djs" v-else>{{ afterseconds }}</div>
          </div>
          <van-button @click.stop="reg">提交注册</van-button>
          <div class="u-reg">已有账号？<span @click.stop="goback('/login')">前往登录</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httppost} from "@/libs/api";
import { useRouter } from 'vue-router'
import {Toast} from "vant";

export default {
  name: 'Home',
  setup() {
    const router = useRouter();
    const state = reactive({
      banner: require('@/assets/default/imgs/aboutus.jpg'),
      issend: false,
      afterseconds: '',
      regform:{
        account:'',
        password:'',
        confirm_pass:'',
        code:''
      },
    })
    const reg = async () => {
      if( !state.regform.account ){
        Toast.fail('输入手机号');
        return false;
      }
      if( !(/^1[345789]\d{9}$/.test(state.regform.account)) ){
        Toast.fail('手机号不正确');
        return false;
      }
      if( !state.regform.password ){
        Toast.fail('输入登陆密码');
        return false;
      }
      if( !state.regform.confirm_pass ){
        Toast.fail('输入重复密码');
        return false;
      }
      if( state.regform.password != state.regform.confirm_pass  ){
        Toast.fail('密码不一致');
        return false;
      }
      if( !state.regform.code ){
        Toast.fail('输入验证码');
        return false;
      }
      let res = await httppost("/api/login/register",state.regform);
      if( res.code == 0 ){
        Toast.success(res.msg);
        setTimeout(() => {
          router.push({path: '/login'}).catch(err => {err});
        }, 1500);
      } else {
        Toast.fail(res.msg);
        return false;
      }
    }
    const getcode = async() =>{
      if( !state.regform.account ){
        Toast.fail('输入手机号');
        return false;
      }
      if( !(/^1[345789]\d{9}$/.test(state.regform.account)) ){
        Toast.fail('手机号不正确');
        return false;
      }
      let res = await httppost('/api/login/sendcode',{mobile:state.regform.account});
      if( res.code == 0 ){
        state.issend = true;
        //倒计时
        djs(res.data);
      } else {
        Toast.fail(res.msg);
        return false;
      }
    }
    const djs = (seconds) =>{
      state.afterseconds = seconds+'秒后重新发送';
      var timer = setInterval(function(){
        seconds --;
        if( seconds > 0 ){
          state.afterseconds = seconds+'秒后重新发送';
        } else {
          clearInterval(timer)
          state.issend = false;
        }
      },1000);
    }
    onMounted(() => {
    })
    return {
      ...toRefs(state),
      reg,
      getcode,
      djs
    };
  },
}
</script>
